<script>
import ListView from '@/components/ListView'
export default {
  extends: ListView,
  data() {
    return {
      namePage: this.$i18n.t('companiesConfig'),
      path: 'companiesConfig',
      headerContent: () => import('@/views/user/components/UsersHeaderComponent.vue'),
      filterContent: () => import('./components/CompaniesFilterComponent.vue'),
      dataProperties: { path: 'companiesConfig', namePage: this.$i18n.t('companiesConfig') },
      headers: [
        {
          text: this.$i18n.t('companyCode'),
          align: 'start',
          sortable: false,
          value: 'cnpj',
        },
        {
          text: this.$i18n.t('Provider'),
          align: 'start',
          sortable: false,
          value: 'provider',
        },
        {
          text: this.$i18n.t('actions'),
          sortable: false,
          value: 'actions',
        },
      ],
    }
  },
}
</script>
